import * as React from 'react';

function Category(props) {
  const { pageContext, location } = props;
  const { collection } = pageContext;

  return <div />;
}

export default Category;
